<template>
  <el-dialog title="新增综合资源" :fullscreen="true" :modal-append-to-body="true"
             :append-to-body="true" :close-on-click-modal="false" :visible.sync="visible"
             :before-close="handleClose">
    <div class="topBuzhou">
      <el-steps :active="active" align-center>
        <el-step title="基本信息" description="请填写资源基本信息"></el-step>
        <el-step title="上传文件" description="请上传资源文件"></el-step>
        <el-step title="提交成功" description="资源提交成功"></el-step>
      </el-steps>
    </div>

    <el-form v-show="active == 0" size="small" :model="detailsForm" :rules="rules" ref="detailsForm"
             label-width="120px">
      <el-form-item label="缩略图：" prop="url">
        <div class="flex_l_t">
          <div
              v-for="(item,index) in upDateList" :key="index"
              style="position: relative;padding-bottom: 10px;width: 110px;margin-right: 15px"
          >
            <div class="el-icon-error" @click="deleteFile(index)"
                 style="position:absolute; top: -8px;right: -8px;font-size: 16px;z-index: 99;cursor: pointer"></div>
            <el-image
                style="width: 100%; height: 110px;"
                :src="item.netUrl"
                :fit="'cover'"
                :preview-src-list="[item.netUrl]"
            >
              <div slot="error" class="image-slot">
                <el-image
                    style="width: 100%; height: 110px;"
                    :src="require('@/assets/img/default.png')"
                    :fit="'cover'">
                </el-image>
              </div>
            </el-image>
            <el-tooltip class="item" effect="dark" :content="item.fileName" placement="top">
              <div style="font-size: 12px;width: 100%;margin-top: -18px;" class="omit">{{
                  item.fileName
                }}
              </div>
            </el-tooltip>
          </div>
          <upload v-show="upDateList.length == 0" :uploadAskObj="uploadRequire2" @getDataList="getImgData">
            <template v-slot:updateStyle>
              <div style="width: 100px;
                          text-align: center;
                          height: 100px;
                          line-height: 100px;
                          border: 1px dashed #d9d9d9;
                          border-radius: 6px;
                          ">
                <div class="el-icon-plus" style="
                            font-size: 20px;
                              "></div>
              </div>
            </template>
          </upload>
        </div>
      </el-form-item>
      <el-form-item label="资源名称：" prop="resourceName">
        <el-input v-model.trim="detailsForm.resourceName"
                  placeholder="请填写资源名称(限50字)"
                  style="width: 300px"
                  maxlength="50" clearable></el-input>
      </el-form-item>
      <el-form-item label="成文时间：" prop="writtenDate">
        <el-date-picker
            v-model="detailsForm.writtenDate"
            type="date" clearable
            value-format="yyyy-MM-dd"
            style="width: 300px"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="公开状态：" prop="openFlag">
        <el-switch
            v-model="detailsForm.openFlag"
            active-value="0"
            inactive-value="1">
        </el-switch>
      </el-form-item>
      <el-form-item label-width="0" prop="resourceDesc">
        <div class="text_right" style="width: 108px">资源描述：</div>
        <el-input
            type="textarea"
            style="padding-left: 38px"
            placeholder="请输入内容（限100字）"
            maxlength="100"
            rows="5"
            v-model="detailsForm.resourceDesc"
        >
        </el-input>
      </el-form-item>
    </el-form>

    <div v-show="active == 1" style="margin-top: 15px">
      <div class="flex_l_c">
        <div class="text_right" style="width: 110px">上传文件：</div>
        <el-dropdown class="m_r1 m_l1">
          <el-button size="small" type="primary">选择文件</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="selectFile(0)">三维资源</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(1)">图片资源</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(2)">音频资源</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(3)">视频资源</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(4)">出版物数据</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(5)">文献数据</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(6)">文件资料</el-dropdown-item>
            <el-dropdown-item @click.native="selectFile(7)">其他资源</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-button size="small" type="primary" @click="createFolder()">创建文件夹</el-button>
      </div>
      <div class="upTishiao contentPadd">单个文件不超过<span>2G</span>，文件越大，上传时间越久。上传完成之前请耐心等待。
      </div>
      <div class="contentPadd contentDate">
        <resume-upload ref="resumeUpload" :fetchUrl="fetchUrl" @upFileDate="upFileDate" :allowFiles="allowFiles"
                       :upFileSize="upFileSize" :dir="'resource'"
                       :hideUploadPlugin="true" :allowFilesShow="false"></resume-upload>
        <div class="flex_b_c" style="margin-bottom: 15px">
          <div style="flex: 1" class="flex_l_c">
            <div>当前路径：</div>
            <div class="flex_b_c">
              <i class="swipeZhis el-icon-arrow-left"></i>
              <swiper :options="swiperOptions" class="maindboaxe">
                <swiper-slide v-for="(item,index) in pathAddress" :key="item.id"
                              :class="['itemBreadcrumb',index == pathAddress.length - 1 ? 'Selected' : '']"
                              >
                  <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                    <div @click="catalogChanges(item,index)" class="omit" style="max-width: 100px">{{ item.name }} {{ index == pathAddress.length - 1 ? '' : '/' }}</div>
                  </el-tooltip>
                </swiper-slide>
              </swiper>
              <i class="swipeZhis el-icon-arrow-right"></i>
            </div>
          </div>
          <el-input size="small" style="width: 200px" prefix-icon="el-icon-search" v-model="searchValue"
                    @keyup.enter.native="getDataList(1)"
                    placeholder="请输入文件名称" maxlength="50" clearable></el-input>
        </div>

        <el-table
            :data="dataList"
            size="small"
            v-loading="loading"
            ref="multipleTable"
            height="300px"
            class="table"
            :row-key="'id'"
            highlight-current-row
            @current-change="handleCurrentChange"
            v-el-table-infinite-scroll="getDataList"
            :header-cell-style="{background:'#F9DFDF'}"
            @selection-change="selectionChangeHandle"
        >
          <el-table-column :reserve-selection="true" type="selection" width="50"/>
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="url" label="文件预览" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image v-if="scope.row.folderFlag == 0" fit="contain" :src="require('@/assets/img/folder.png')"
                        class="itemImg"></el-image>
              <el-image v-else fit="contain" :src="scope.row.url" class="itemImg">
                <el-image slot="placeholder" fit="cover" class="itemImg"
                          :src="getPlaceholderImg(scope.row.type)"></el-image>
                <el-image slot="error" fit="cover" class="itemImg"
                          :src="getPlaceholderImg(scope.row.type)"></el-image>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="fileName" label="文件名称" width="250px" show-overflow-tooltip>
            <template slot-scope="scope">
              <div @dblclick="goEdit(scope.row)" v-if="!scope.row.edit">{{ scope.row.fileName }}</div>
              <el-input v-model.trim="scope.row.fileName"
                        placeholder="请填写文件名称(限60字)" size="small"
                        @input="scope.row.fileName = scope.row.fileName.replace(/[^\u4e00-\u9fa5a-zA-Z0-9_]/g, '')"
                        @change="editFolder(scope.row)"
                        v-focus
                        @blur="editFolder(scope.row)"
                        style="width: 200px" v-else
                        maxlength="60" clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="typeName" label="文件类型" show-overflow-tooltip></el-table-column>
          <el-table-column prop="owningFolder" label="所在文件夹" show-overflow-tooltip></el-table-column>
          <el-table-column prop="fileSizeFormat" label="文件大小" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button style="margin-bottom: 10px" size="mini" type="text"
                         @click="deleteData(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div></div>
    </div>

    <div v-show="active == 2">
      <el-result icon="success" title="成功提示">
        <template slot="extra">
          <div style="font-size: 12px;margin-bottom: 20px">成功新增资源：{{ detailsForm.resourceName }},文件数量{{ count }}个</div>
          <el-button type="primary" size="medium" @click="handleClose()">确认</el-button>
        </template>
      </el-result>
    </div>

    <div class="text_center" style="margin-top: 15px">
      <div v-show="active == 0">
        <el-button size="small" type="primary" @click="nextSetUp(0)">下一步</el-button>
      </div>

      <div v-show="active == 1">
        <el-button size="small" type="primary" @click="nextSetUp(1)">上一步</el-button>
        <el-button size="small" type="primary" @click="submitTo()" v-no-more-click>提交</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import Upload from "@/components/Upload/Upload";
import ResumeUpload from "@/components/Upload/resumeUpload.vue";

export default {
  name: "addComprehensive",
  components: {ResumeUpload, Upload},
  data() {
    return {
      visible: false,
      active: 0,
      detailsForm: {
        url: '',
        resourceName: '',
        writtenDate: null,
        openFlag:'1',
        resourceDesc: '',
      },
      rules: {
        resourceName: [
          {required: true, message: '请填写资源名称', trigger: 'blur'},
        ],
      },
      upDateList: [],
      // 缩略图上传要求
      uploadRequire2: {
        list: [],
        showFile: false, // 是否显示已上传文件列表
        count: 1, // 上传文件数量 0表示不限制文件的数量
        fileType: ['jpg', 'png', 'jpeg'],
        megaNum: 10,
        showTips: true,
        customUploadStyle: true,
      },

      // Swiper 配置项
      swiperOptions: {
        // 例如，开启自动轮播
        autoplay: false,
        // 设置轮播速度
        speed: 500,
        // 开启循环模式
        loop: false,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.el-icon-arrow-right', // 指定下一个按钮的DOM选择器
          prevEl: '.el-icon-arrow-left', // 指定上一个按钮的DOM选择器
          // 这里可以添加更多配置项来自定义按钮的样式和行为，但上面两个是最基本的
        },
        // ... 其他配置项
      },
      pathAddress: [
        {
          id: '',
          name: '//'
        },
      ],
      searchValue: '',

      fetchUrl: '',
      allowFiles: [],
      upFileSize: 2048,
      fileType: '',

      regex:/^[\u4e00-\u9fa5a-zA-Z0-9_]+$/,
      resourceId: '',
      parentId: '',
      dataList: [],
      notEnterFolder:false,
      loading: false,
      dataListSelect: [],
      current: 1,
      size: 20,
      total: 0,
      count:0,
    }
  },

  methods: {
    init() {
      this.visible = true
      this.active = 0
    },

    deleteFile(index) {
      this.$confirm('此操作将删除该缩略图, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.upDateList.splice(index, 1)
        this.uploadRequire2.list = []
        this.detailsForm.url = ''
      })
    },

    // 获取上传缩略图数据
    getImgData(data) {
      this.upDateList = [
        {
          netUrl: data.response.data.url,
          fileName: data.response.data.name,
        }
      ]
      this.detailsForm.url = data.response.data.path
    },

    //0下一步 1上一步
    nextSetUp(num) {
      if (num == 1){
        this.active--
      }else {
        if (this.active == 0) {
          this.$refs.detailsForm.validate((valid) => {
            if (valid) {
              this.$axios(this.api.digital.comprehensiveResourcesSave, {
                ...this.detailsForm,
              }, 'post').then((res) => {
                if (res.status) {
                  this.resourceId = res.data
                  this.active++
                  this.getDataList(1)
                }
              })
            }
          });
        }
      }
    },

    //进入文件夹
    handleCurrentChange(row){
      setTimeout(()=>{
        if (!this.notEnterFolder && row.folderFlag == 0){
          let editLista = this.dataList.filter(item=>{ return item.edit == true})
          if (!editLista.length) {
            this.parentId = row.id
            this.getDataList(1)
          }
        }
      },200)
    },

    //查询目录数据
    getDataList(current) {
      if (current) {
        this.current = current;
        this.dataList = []
      } else {
        if (this.current * this.size < this.total) {
          this.current++
        } else {
          return
        }
      }
      this.loading = true
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkList, {
        parentId: this.parentId,
        resourceId: this.resourceId,
        current: this.current,
        size: this.size,
        fileName: this.searchValue,
      }, 'post').then((res) => {
        if (res.status) {
          this.pathAddress = [{id: '', name: '//'},]
          if (res.data.resourcePath.length){
            this.pathAddress.push(...res.data.resourcePath)
          }
          this.dataList.push(...res.data.fileLinkList.records);
          this.total = parseInt(res.data.fileLinkList.total);
          this.dataList.forEach(item=>{
            this.$set(item,'edit',false)
          })
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      })
    },

    //点击目录
    catalogChanges(row, index) {
      this.parentId = row.id
      this.pathAddress.splice(0, index + 1)
      this.getDataList(1)
    },

    //保存上传文件
    upFileDate(data) {
      let row = data.map((item, index) => {
        if (item.upDate) {
          this.$set(item.upDate, 'fileID', item.id)
          this.$set(item.upDate, 'fileBrowse', item.id)
          this.$set(item.upDate, 'fileSort', index + 1)
          this.$set(item.upDate, 'masterView', 1)
          this.$set(item.upDate, 'fileSize', item.origSize)
          return item.upDate
        } else {
          return item
        }
      })
      let files = []
      if (this.fileType != 0) {
        files = [
          {
            fileFormat: row[0].fileFormat,
            fileName: row[0].fileName,
            fileSize: row[0].fileSize,
            fileSizeFormat: row[0].fileSizeFormat,
            url: row[0].filePath,
            type: this.fileType,
          }
        ]
      } else {
        row[0].fileList.forEach(item => {
          files.push(
              {
                fileFormat: item.fileFormat,
                fileName: item.fileName,
                fileSize: item.fileSize,
                fileSizeFormat: item.fileSizeFormat,
                url: item.filePath,
                type: this.fileType,
              }
          )
        })
      }
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkSave, {
        files,
        folderFlag: 1,
        folderName: this.fileType == 0 ? row[0].compressName.split('.')[0] : '',
        parentId: this.parentId,
        resourceId: this.resourceId,
      }, 'post').then((res) => {
        if (res.status) {
          this.getDataList(1)
        } else {
          this.$message.error(res.msg)
        }
      })
      this.$refs.resumeUpload.deleteFile(row[0].fileID, '', 1)
    },

    selectFile(fileType) {
      this.fileType = fileType
      this.fetchUrl = ''
      if (this.fileType == '0') {
        this.fetchUrl = 'zyd-common/file/uploadThreeFile'
      }
      this.allowFiles = this.getResourceUploadFileFormat(fileType)
      console.log(this.allowFiles)
      this.$nextTick(() => {
        this.$refs.resumeUpload.$refs.upFile.click()
      })
    },

    //创建文件夹
    createFolder() {
      this.$prompt('请输入文件夹名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (!value || value.length == 0) {
            return '文件夹名称不能为空'
          }
          if (value.length > 60) {
            return '文件夹名称最长为60个字符'
          }
          if (!this.regex.test(value)){
            return '文件夹名称不正确'
          }
        },
        inputErrorMessage: '文件夹名称不正确',
        inputPlaceholder:'请输入文件夹名称(限60字)',
      }).then(({value}) => {
        this.$axios(this.api.digital.comprehensiveResourcesFileLinkSave, {
          files:[],
          folderFlag: 0,
          folderName: value,
          parentId: this.parentId,
          resourceId: this.resourceId,
        }, 'post').then((res) => {
          if (res.status) {
            this.$message.success('添加成功')
            this.getDataList(1)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },

    //进入编辑状态
    goEdit(row){
      row.edit = !row.edit
      this.notEnterFolder = true
    },

    //修改文件夹保存
    editFolder(row){
      this.$axios(this.api.digital.comprehensiveResourcesFileLinkUpdateById, {
        id:row.id,
        folderFlag: row.folderFlag,
        fileId:row.fileId,
        folderName: row.fileName,
        parentId: this.parentId,
        resourceId: this.resourceId,
      }, 'put').then((res) => {
        if (res.status) {
          this.$message.success('修改成功')
          row.edit = false
          this.notEnterFolder = false
          this.getDataList(1)
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    //删除
    deleteData(row){
      this.notEnterFolder = true
      this.$confirm(`您是否确认删除该${row.folderFlag == 0 ? '文件夹' : '文件'}?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios(this.api.digital.comprehensiveResourcesFileLinkRemoveById + row.id, {}, 'delete').then(data => {
          if (data && data.status) {
            this.$message.success('删除成功')
            this.getDataList(1)
          } else {
            this.$message.error(data.msg)
          }
          this.notEnterFolder = false
        })
      }).catch(()=>{
        this.notEnterFolder = false
      })
    },

    //提交
    submitTo(){
      this.$axios(this.api.digital.comprehensiveResourcesSubmit, {
        ...this.detailsForm,
        whetherToSubmit:0,
        id:this.resourceId,
      }, 'post').then((res) => {
        if (res.status) {
          this.$message.success('提交成功')
          this.count = res.data.count
          this.active++;
          this.$emit('refreshList')
        } else {
          this.$message.error(res.msg)
        }
      })
    },

    // 获取占位图
    getPlaceholderImg(resourceType) {
      switch (resourceType) {
        case '0':
          return require('@/assets/img/collThree.png')
        case '1':
          return require('@/assets/img/collPhoto.png')
        case '2':
          return require('@/assets/img/collAudio.png')
        case '3':
          return require('@/assets/img/collVideo.png')
        default:
          return require('@/assets/img/collFile.png')
      }
    },

    //表格勾选数据
    selectionChangeHandle(val) {
      this.dataListSelect = val
    },

    handleClose() {
      this.$refs.detailsForm.resetFields()
      this.searchValue = ''
      this.upDateList = []
      this.uploadRequire2.list = []
      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.topBuzhou {
  padding: 0 20% 15px;
}

.upTishiao {
  margin-top: 10px;
  font-size: 10px;

  span {
    font-weight: bold;
  }
}

.contentPadd {
  padding: 0 117px;
}

.contentDate {
  margin-top: 15px;
}

.itemBreadcrumb {
  cursor: pointer;
}

.Selected {
  font-weight: bold !important;
}

.maindboaxe {
  .swiper-slide {
    width: auto;
  }

  width: 700px;
  flex: 1;
}

.swipeZhis {
  font-size: 18px;
  padding: 0 5px;
  cursor: pointer;
}

.itemImg {
  height: 50px;
  width: auto;
}
</style>